<template>
  <div v-if="isVisible" :class="getClass" @click="hide" class="fixed flex items-center justify-between w-full max-w-sm p-4 space-x-4 text-white bg-gray-800 rounded-lg shadow top-5 right-5">
    <div class="text-sm font-normal message" v-html="$t(message)"></div>
    <GenericButton
        theme="inherit-text"
        @click="hide" type="button" class="text-disabled rounded-lg hover:text-gray-200 focus:ring-2 p-1.5 inline-flex h-10 w-10" data-dismiss-target="#toast-warning" aria-label="Close">
      <span class="sr-only">{{$t('Close')}}</span>
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </GenericButton>
  </div>
</template>

<script>
import GenericButton from './GenericButton.vue';

export default {
    components:{
        GenericButton
    },
  data() {
    return {
      message: '',
      type: '',
      isVisible: false,
      timeout: 2000
    }
  },

  methods: {
    show(message, type, timeout = 2000) {
      this.message = message
      this.type = type
      this.isVisible = true
      this.timeout = timeout

      setTimeout(() => {
        this.hide()
      }, timeout)
    },

    hide() {
      this.isVisible = false
      this.message = ''
      this.type = ''
    }
  },

  computed: {
    getClass() {
      return [
        'ovn-toast',
        `ovn-toast-${this.type}`,
        {
          'ovn-toast-visible': this.isVisible
        }
      ]
    }
  }
}
</script>

<style>
.ovn-toast {
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  transition: transform 0.3s ease-out;
  z-index: 100000;
}

@media (max-width: 767px) {
  .ovn-toast {
    top: unset;
    bottom: 20px;
  }
}

.ovn-toast-success {
  background-color: #4bb543;
}

.ovn-toast-error {
  background-color: #f56565;
}

.ovn-toast-warning {
  background-color: #ed8936;
}

.ovn-toast-info {
  background-color: #63b3ed;
}

.ovn-toast-visible {
  transform: translateY(0);
}

.ovn-toast .message {
  word-wrap: break-word;
}

</style>
